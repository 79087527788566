<template>
    <div class="candidate-base-info-store-vue" data-spm-area-id="A_candidate_detail_top">
        <div
            ref="candidateBaseInfo"
            class="candidate-base-info"
            :class="[!isFirmResume && !isCloudRecommendation ? 'personal-resume' : '', isCloudRecommendation ? 'cloud-resume' : '']"
            v-loading="detailLoading">
            <div class="offer-seal" v-if="lockStatus.isOfferStamp">
                <el-tooltip placement="right" content="已offer,请谨慎操作" effect="light" popper-class="concat-tooltip" :visible-arrow="false">
                    <img src="~@src/assets/images/candidateDetail/offer-seal.png" alt="offer 印章">
                </el-tooltip>
            </div>
            <!-- <div class="candidate-status">
                <div class="tag-jobHunting" v-show="isJobHunting"></div>
            </div> -->
            <img v-if="!isFirmResume && !isCloudRecommendation" class="private-bg" src="~@src/assets/images/candidateDetail/private-bg.png" alt="私人简历">
            <img v-if="!isFirmResume && isCloudRecommendation" class="private-bg" src="~@src/assets/images/candidateDetail/cloud-bg.png" alt="私人简历">
            <el-row class="resume-headline">
                <el-col :span="24">
                    <p class="headline-base">
                        <span class="headline-name" :title="realName">{{realName || '未知'}}</span>
                        <span class="completion-percent" v-show="completionPercent">
                            <i class="el-icon-star-on"></i>
                            <a title="完整度" class="resume-text" v-text="completionPercent"></a>
                        </span>
                        <span v-show="isJobHunting" class="job-hunting-tag">
                            求职
                        </span>
                        <span class="headline-creator" v-show="creatorRealName">
                            <avatar
                                placement="right"
                                :enableCard="true"
                                :enableLink="true"
                                :shareData="shareData"
                                class="headline-creator-avatar"
                                :src="candidateDetail.creatorAvatarUrl"
                                :userId="candidateDetail.creatorId"
                            ></avatar>
                            <i class="headline-title"><span>创建者：</span>{{ candidateDetail.creatorRealName }}@{{ candidateDetail.creatorNickname }}</i>
                        </span>

                        <a
                            class="pull-right go-to-firm-resume"
                            v-show="!isFirmResume && existedResumeId"
                            :href="`/#/candidateDetail/${existedResumeId}`"
                            target="_blank">
                            查看企业简历
                        </a>
                    </p>
                </el-col>
                <el-col :span="24">
                    <div class="headline-base">
                        <span v-if="yearOfExperience && yearOfExperience != '未知'">{{yearOfExperience}}年工作经验</span>
                        <span v-else>工作经验未知</span>
                        <i v-if="birthday && birthday.indexOf('0001-01-01') != 0" class="headline-border">|</i>
                        <span v-if="birthday && birthday.indexOf('0001-01-01') != 0">{{birthday | birthdayToAge}}</span>
                        <i v-show="gender" class="headline-border">|</i>
                        <span v-show="gender">{{gender == '未知' ? '性别未知' : gender}}</span>
                        <i v-show="cityName" class="headline-border">|</i>
                        <span>{{cityName}}</span>
                        <i class="headline-border">|</i>
                        <span class="degree-text" v-if="degree">{{degree | getDegreeTextById}}</span>
                        <span class="degree-text" v-else>学历不限</span>
                        <i class="headline-border" v-if="!isCloudRecommendation">|</i>
                        <!-- <span>{{school || '未知'}}</span> -->
                        <span class="headline-update" v-if="!isCloudRecommendation"><i class="headline-title">更新时间：</i>{{updateTime | formatDateWithDot }}</span>
                        <span v-if="userInfo.isCFUser && isFirmResume" class="headline-point-tip">更新当前雇主公司和职位可得15积分</span>
                        <!-- <a
                            class="pull-right go-to-firm-resume"
                            v-show="!isFirmResume && existedResumeId"
                            :href="`/Headhunting/MyCompany.html#/candidateDetail/${existedResumeId}`"
                            target="_blank">
                            查看企业简历
                        </a> -->
                    </div>
                </el-col>
            </el-row>
            <div class="candidate-headline">
                <div class="candidate-headline-info-wrap">
                    <div class="candidate-headline-info">
                        <div class="info-item">
                            <template v-if="isFromAuthorization">
                                <p class="resume-info">
                                    <i class="headline-title"><font-icon href="#icon-telephone-out"></font-icon>手机：</i>
                                    <span>
                                        <span class="info-block">
                                            <span>{{mobile || '******'}}</span>
                                        </span>
                                        <!-- <phone-call ref="phoneCall" class="free-call"
                                            v-if="canCall"
                                            :call-to="callTo"
                                            :object-id="candidateId || ''"
                                            :proportion="proportion"
                                            @candidate-search-record-refresh="handelRecordRefresh"
                                            :tbdResumeId="resumeId"
                                            :is-firm-resume="isFirmResume"
                                            sourceFrom="candidateDetail"
                                            :called-mobile="mobile">
                                            <el-button
                                                v-if="!(!isFirmResume && !isCloudRecommendation)">拨打</el-button>
                                        </phone-call> -->
                                        <template v-if="mobileStatus == 2">
                                            <el-tooltip 
                                                placement="bottom" effect="light" popper-class="concat-tooltip" :visible-arrow="false">
                                                <div slot="content">
                                                    候选人正在入职保证期中，暂不能查看其联<br />系方式
                                                </div>
                                                <span class="info-lock-btn" 
                                                    data-event-id="E_candidate_contact_click">
                                                    查看手机
                                                </span>
                                            </el-tooltip>
                                        </template>
                                        <template v-else-if="mobileStatus == 1">
                                            <el-button 
                                                :loading="viewMobileLoading" 
                                                class="info-block-btn" 
                                                type="primary" 
                                                size="mini" 
                                                @click="applyView(1)"
                                                data-event-id="E_candidate_contact_click"
                                            >查看手机</el-button>
                                        </template>
                                        <template v-else>
                                            <el-button 
                                                v-if="mobile=='******' || mobile==null"
                                                :loading="viewMobileLoading" 
                                                class="info-block-btn" 
                                                type="primary" 
                                                size="mini" 
                                                @click="applyView(1)"
                                                data-event-id="E_candidate_contact_click"
                                            >查看手机</el-button>
                                        </template>
                                    </span>
                                </p>
                            </template>
                            <template v-else>
                                <p class="resume-info" v-if="mobileStatus!=2 && !isCloudRecommendation" :data-spm-e-data="getEData('Mobile')" 
                                data-spm-e-id="L_candidate_contact">
                                    <i class="headline-title"><font-icon href="#icon-telephone-out"></font-icon>手机：</i>
                                    <span v-if="mobileStatus==3 || mobileStatus==4">
                                        <span class="info-block">
                                            <!-- <span :class="mobileStatus == 4 ? 'mobile-unable' : ''"  v-text="mobile || 'xxxxxxxxxxx'" v-show="!enableHideMobile"></span> -->
                                            <span :class="mobileStatus == 4 ? 'mobile-unable' : ''"  v-text="mobile || '未知'" v-show="!enableHideMobile"></span>
                                            <span v-show="enableHideMobile && !isFromAuthorization" class="authorization-mobile">手机号推荐后可见</span>
                                            <span v-show="enableHideMobile && isFromAuthorization">{{mobile}}</span>
                                        </span>
                                        <phone-call ref="phoneCall" class="free-call"
                                            v-if="canCall"
                                            :call-to="callTo"
                                            :object-id="candidateId || ''"
                                            :proportion="proportion"
                                            @candidate-search-record-refresh="handelRecordRefresh"
                                            :tbdResumeId="resumeId"
                                            :is-firm-resume="isFirmResume"
                                            sourceFrom="candidateDetail"
                                            :called-mobile="mobile">
                                            <el-button v-if="!(!isFirmResume && !isCloudRecommendation)">拨打</el-button>
                                        </phone-call>
                                    </span>
                                    <el-button :loading="viewMobileLoading" v-if="mobileStatus == 1 && !isFromAuthorization" class="info-block-btn" type="primary" size="mini" @click="applyView(1)" data-event-id="E_candidate_contact_click">查看手机</el-button>
                                    <el-tooltip effect="light" content="标记失效" placement="bottom-start" :visible-arrow="false" popper-class="invalid-popper" v-if="mobileStatus==3 && mobile && !isFromAuthorization">
                                        <span class="invalid-btn" @click="markInvalid('mobile')">
                                            <!-- <font-icon class="candidate-icon" href="#icon-talent_shixiao"></font-icon> -->
                                            <font-icon class="mark-icon" href="#icon-jinyong"></font-icon>
                                        </span>
                                    </el-tooltip>
                                    <el-tooltip effect="light" content="激活号码" placement="bottom-start" :visible-arrow="false" popper-class="invalid-popper" v-if="mobileStatus==4 && mobile && !isFromAuthorization">
                                        <span class="invalid-btn" @click="markValid('mobile')">
                                            <!-- <font-icon class="candidate-icon" href="#icon-candidate_jihuo"></font-icon> -->
                                            <font-icon class="activate-icon" href="#icon-jihuo"></font-icon>
                                        </span>
                                    </el-tooltip>
                                </p>
                                <p class="resume-info" v-if="isCloudRecommendation">
                                    <i class="headline-title"><font-icon href="#icon-telephone-out"></font-icon>手机：</i>
                                    {{ mobile }}
                                </p>
                                <p class="resume-info" v-if="mobileStatus==2 && !isCloudRecommendation && !isFromAuthorization" :data-spm-e-data="getEData('Mobile')" 
                                data-spm-e-id="L_candidate_contact">
                                    <i class="headline-title"><font-icon href="#icon-telephone-out"></font-icon>手机：</i>
                                    <el-tooltip placement="bottom" effect="light" popper-class="concat-tooltip" :visible-arrow="false">
                                        <div slot="content" v-if="userInfo.isAdministrator">
                                            候选人正在入职保证期中，联系方式已被上<br />锁，您可以进行解锁
                                        </div>
                                        <div slot="content" v-else>
                                            候选人正在入职保证期中，暂不能查看其联<br />系方式
                                        </div>
                                        <span class="info-lock-btn" @click="unlockInfo" data-event-id="E_candidate_contact_click">查看手机</span>
                                    </el-tooltip>
                                    <el-tooltip placement="bottom" effect="light" popper-class="concat-tooltip" :visible-arrow="false">
                                        <div slot="content">
                                            候选人正在入职保证期中，暂不能拨打其电话
                                        </div>
                                        <el-button class="free-call">拨打</el-button>
                                    </el-tooltip>
                                </p>
                            </template>
                            <template v-if="isFromAuthorization">
                                <p class="resume-info">
                                    <i class="headline-title"><font-icon href="#icon-email"></font-icon>邮箱：</i>
                                    <span class="info-block" :title="email == '未知' ? '******' : (email || '******')">
                                        <span>{{email == '未知' ? '******' : (email || '******')}}</span>
                                    </span>
                                    <template v-if="emailStatus == 2">
                                        <el-tooltip 
                                            placement="bottom" effect="light" popper-class="concat-tooltip" :visible-arrow="false">
                                            <div slot="content">
                                                候选人正在入职保证期中，暂不能查看其联<br />系方式
                                            </div>
                                            <span class="info-lock-btn" 
                                                data-event-id="E_candidate_contact_click">
                                                查看邮箱
                                            </span>
                                        </el-tooltip>
                                    </template>
                                    <template v-if="emailStatus == 1">
                                        <el-button :loading="viewEmailLoading" v-if="emailStatus == 1" class="info-block-btn" type="primary" size="mini" @click="applyView(2)"  data-event-id="E_candidate_contact_click"
                                        >查看邮箱</el-button>
                                    </template>
                                </p>
                            </template>
                            <template v-else>
                                <p class="resume-info no-elli"  v-if="emailStatus!=2 && !isCloudRecommendation" :data-spm-e-data="getEData('Email')" 
                                data-spm-e-id="L_candidate_contact">
                                    <i class="headline-title"><font-icon href="#icon-email"></font-icon>邮箱：</i>
                                    <span class="info-block no-elli-wrap" v-if="emailStatus==3 || emailStatus==4" :title="email">
                                        <span class="email-text" v-if="email=='未知'" v-text="email"></span>
                                        <a v-else :class="emailStatus ==4 && email!=='未知' ?'email-unable':'email-able'"  v-text="email" :href="emailStatus ==4 ? 'javascript:;' : `mailto:${email}`"></a>
                                    </span>
                                    <el-button :loading="viewEmailLoading" v-if="emailStatus == 1" class="info-block-btn" type="primary" size="mini" @click="applyView(2)" data-event-id="E_candidate_contact_click">查看邮箱</el-button>
                                    <el-button @click="onShowMailDialog" v-if="email!=='未知'">发邮件</el-button>
                                    <el-tooltip effect="light" content="标记失效" placement="bottom-start" :visible-arrow="false" popper-class="invalid-popper" v-if="emailStatus==3 && email!=='未知' && !isFromAuthorization">
                                        <span class="invalid-btn" @click="markInvalid('email')">
                                            <!-- <font-icon class="candidate-icon" href="#icon-talent_shixiao"></font-icon> -->

                                            <font-icon class="mark-icon" href="#icon-jinyong"></font-icon>
                                        </span>
                                    </el-tooltip>
                                    <el-tooltip effect="light" content="激活邮箱" placement="bottom-start" :visible-arrow="false" popper-class="invalid-popper" v-if="emailStatus==4 && email!=='未知' && !isFromAuthorization">
                                        <span class="invalid-btn" @click="markValid('email')">
                                            <!-- <font-icon class="candidate-icon" href="#icon-candidate_jihuo"></font-icon> -->
                                            <font-icon class="activate-icon" href="#icon-jihuo"></font-icon>
                                        </span>
                                    </el-tooltip>
                                </p>
                                <p class="resume-info" v-if="isCloudRecommendation">
                                    <i class="headline-title"><font-icon href="#icon-email"></font-icon>邮箱：</i>
                                    {{ email }}
                                </p>
                                <p class="resume-info" v-if="emailStatus==2 && !isCloudRecommendation" :data-spm-e-data="getEData('Email')" 
                                data-spm-e-id="L_candidate_contact">
                                    <i class="headline-title"><font-icon href="#icon-email"></font-icon>邮箱：</i>
                                    <el-tooltip placement="bottom" effect="light" popper-class="concat-tooltip"  :visible-arrow="false">
                                        <div slot="content" v-if="userInfo.isAdministrator">
                                            候选人正在入职保证期中，联系方式已被上<br />锁，您可以进行解锁
                                        </div>
                                        <div slot="content" v-else>
                                            候选人正在入职保证期中，暂不能查看其联<br />系方式
                                        </div>
                                        <span class="info-lock-btn" @click="unlockInfo" data-event-id="E_candidate_contact_click">查看邮箱</span>
                                    </el-tooltip>
                                </p>
                            </template>
                            <template v-if="isFromAuthorization">
                                <p class="resume-info">
                                    <i class="headline-title"><font-icon href="#icon-xiaoxi-zhihuibeifen"></font-icon>微信：</i>
                                    <span>
                                        <span class="info-block">
                                            <span>{{wechat || '未知'}}</span>
                                        </span>
                                        <template v-if="wechatStatus == 2 && (wechat=='' || wechat=='******')">
                                            <el-tooltip 
                                                placement="bottom" effect="light" popper-class="concat-tooltip" :visible-arrow="false">
                                                <div slot="content">
                                                    候选人正在入职保证期中，暂不能查看其联<br />系方式
                                                </div>
                                                <span class="info-lock-btn" 
                                                    data-event-id="E_candidate_contact_click">
                                                    查看微信
                                                </span>
                                            </el-tooltip>
                                        </template>
                                        <template v-if="wechatStatus == 1">
                                            <el-button
                                                :loading="viewWechatLoading"
                                                v-if="wechatStatus == 1"
                                                class="info-block-btn"
                                                type="primary"
                                                size="mini"
                                                @click="applyView(3)"
                                                data-event-id="E_candidate_contact_click"
                                                >
                                                查看微信
                                            </el-button>
                                        </template>
                                        <el-button
                                            ref="copyWechatBtn"
                                            v-if="wechat !== '未知' && wechatStatus != 3">
                                            复制微信
                                        </el-button>
                                    </span>
                                </p>
                            </template>
                            <template v-else>
                                <p
                                    class="resume-info"
                                    v-if="wechatStatus != 2 && !isCloudRecommendation"
                                    :data-spm-e-data="getEData('Wechat')" 
                                    data-spm-e-id="L_candidate_contact"
                                    >
                                    <i class="headline-title"><font-icon href="#icon-xiaoxi-zhihuibeifen"></font-icon>微信：</i>
                                    <span
                                        class="info-block"
                                        v-if="wechatStatus == 3 || wechatStatus == 4">
                                        <span
                                            :class="wechatStatus == 4 ? 'wechat-unable' : ''"
                                            v-text="wechat"
                                        ></span>
                                    </span>
                                    <template v-if="wechatStatus == 2 && (wechat=='' || wechat=='******')">
                                        <el-tooltip 
                                            placement="bottom" effect="light" popper-class="concat-tooltip" :visible-arrow="false">
                                            <div slot="content">
                                                候选人正在入职保证期中，暂不能查看其联<br />系方式
                                            </div>
                                            <span class="info-lock-btn" 
                                                data-event-id="E_candidate_contact_click">
                                                查看微信
                                            </span>
                                        </el-tooltip>
                                    </template>
                                    <template v-if="wechatStatus == 1">
                                        <el-button
                                            :loading="viewWechatLoading"
                                            v-if="wechatStatus == 1"
                                            class="info-block-btn"
                                            type="primary"
                                            size="mini"
                                            @click="applyView(3)"
                                            data-event-id="E_candidate_contact_click"
                                            >
                                            查看微信
                                        </el-button>
                                    </template>
                                    <!-- <el-button
                                        :loading="viewWechatLoading"
                                        v-if="wechatStatus == 1"
                                        class="info-block-btn"
                                        type="primary"
                                        size="mini"
                                        @click="applyView(3)"
                                        data-event-id="E_candidate_contact_click">
                                        查看微信
                                    </el-button> -->
                                    <el-button
                                        ref="copyWechatBtn"
                                        v-if="wechat !== '未知' && wechat!='' && wechat!='******'">
                                        复制微信
                                    </el-button>
                                    <el-tooltip
                                        effect="light"
                                        content="标记失效"
                                        placement="bottom-start"
                                        :visible-arrow="false"
                                        popper-class="invalid-popper"
                                        v-if="wechatStatus == 3 && wechat !== '未知' && !isFromAuthorization">
                                        <span
                                            class="invalid-btn"
                                            @click="markInvalid('wechat')">
                                            <!-- <font-icon
                                                class="candidate-icon"
                                                href="#icon-talent_shixiao"
                                            ></font-icon> -->
                                            <font-icon class="mark-icon" href="#icon-jinyong"></font-icon>
                                        </span>
                                    </el-tooltip>
                                    <el-tooltip
                                        effect="light"
                                        content="激活微信"
                                        placement="bottom-start"
                                        :visible-arrow="false"
                                        popper-class="invalid-popper"
                                        v-if="wechatStatus==4 && wechat !== '未知' && !isFromAuthorization">
                                        <span class="invalid-btn" @click="markValid('wechat')">
                                            <!-- <font-icon class="candidate-icon" href="#icon-candidate_jihuo"></font-icon> -->
                                            <font-icon class="activate-icon" href="#icon-jihuo"></font-icon>
                                        </span>
                                    </el-tooltip>
                                </p>
                                <p
                                    class="resume-info"
                                    v-if="isCloudRecommendation">
                                    <i class="headline-title"><font-icon href="#icon-xiaoxi-zhihuibeifen"></font-icon>微信：</i>
                                    {{ wechat }}
                                </p>
                                <p
                                    class="resume-info"
                                    v-if="wechatStatus == 2 && !isCloudRecommendation"
                                    :data-spm-e-data="getEData('Wechat')" 
                                    data-spm-e-id="L_candidate_contact">
                                    <i class="headline-title"><font-icon href="#icon-xiaoxi-zhihuibeifen"></font-icon>微信：</i>
                                    <el-tooltip
                                        placement="bottom"
                                        effect="light"
                                        popper-class="concat-tooltip"
                                        :visible-arrow="false">
                                        <div slot="content" v-if="userInfo.isAdministrator">
                                            候选人正在入职保证期中，联系方式已被上<br />锁，您可以进行解锁
                                        </div>
                                        <div slot="content" v-else>
                                            候选人正在入职保证期中，暂不能查看其联<br />系方式
                                        </div>
                                        <span
                                            class="info-lock-btn"
                                            @click="unlockInfo"
                                            data-event-id="E_candidate_contact_click">
                                            查看微信
                                        </span>
                                    </el-tooltip>
                                </p>
                            </template>
                        </div>
                        <div class="info-item">
                            <p class="school">
                                <i class="headline-title"><font-icon href="#icon-work"></font-icon></i>
                                <span class="school-text" :title="school">{{school || '学校未知'}}</span>
                                <span class="major-text" :title="major">{{major ? `-${major}` : '-专业未知'}}</span>
                                <span class="degree-text" v-if="degree">{{degree | getDegreeTextById}}</span>
                                <span class="degree-text" v-else>学历不限</span>
                            </p>
                            <p class="company">
                                <i class="headline-title"><font-icon href="#icon-lieqishezhibeifen"></font-icon></i>
                                <span class="company-text" :title="company">{{company || '公司未知'}}</span>
                                <span class="title-text" :title="candidateTitle">{{candidateTitle|| '职位未知'}}</span>
                            </p>
                            <p class="company" v-if="isFirmResume && !!customerDepartmentName" :title="customerDepartmentName">
                                <i class="headline-title"><font-icon href="#icon-lieqishezhibeifen"></font-icon></i>
                                <span class="department-text">{{customerDepartmentName}}</span>
                            </p>
                            <p class="new-first-city-chance" v-if="isFirmResume">
                                <i class="headline-title"><font-icon href="#icon-offerbeifen"></font-icon>新一线机会：</i>
                                <span class="first-time" @click="addNewFirstCity" v-if="isCFuser && !newCityExpectationsInfo?.id">首次填写，加10积分</span>
                                <span class="first-time" @click="addNewFirstCity" v-if="!isCFuser && !newCityExpectationsInfo?.id">填写</span>
                                <span class="new-first-city-content" v-else>
                                    <span class="city-text" :title="newCityExpectationsText">{{newCityExpectationsText}}</span>
                                    <span class="option modification" @click="editNewFirstCity" v-if="isCFuser && newCityExpectationsInfo?.id">
                                        <font-icon href="#icon-bianji"></font-icon>修改
                                    </span>
                                    <span class="option view-log" @click="viewLogs">
                                        <font-icon href="#icon-xiangmujingli-04"></font-icon>查看日志
                                    </span>
                                </span>
                            </p>
                            <p v-else></p>
                        </div>
                    </div>

                    <div class="resume-label" v-if="!isCloudRecommendation">
                        <!-- <candidate-label-cloud ref="candidateLabelCloud" :resume-id="resumeId" @collect-label-refresh='handleCollectLabel' class="candidate-label-classify"></candidate-label-cloud> -->
                        <candidate-labels 
                            ref="candidateLabels" 
                            :resumeId="resumeId" 
                            @collect-label-refresh='handleCollectLabel'
                            @showLabelManagement="showLabelManagement"
                        ></candidate-labels>
                    </div>
                </div>
                <div class="candidate-headline-option-wrap" v-if="!isCloudRecommendation">
                    <div class="candidate-headline-option">
                        <div class="option-btn resume-edit-btn" @click="onEmailShare" v-if="isCloudRecommendation">
                            <font-icon class="candidate-icon" href="#icon-ic_transmit_xq"></font-icon>
                            <span class="edit-text">转发</span>
                        </div>
                        <div class="option-btn resume-edit-btn" @click="onPrint" v-if="isCloudRecommendation">
                            <font-icon class="candidate-icon" href="#icon-ic_print_xq"></font-icon>
                            <span class="edit-text">打印</span>
                        </div>
                        <div class="option-btn collect-btn" v-if="!isCloudRecommendation">
                            <span class="clollect-text" @mouseover="showCollectPopup" @click="onCandidateCollect">
                                <el-tooltip effect="light" :content="isCollected ? '取消收藏':'加入Talent Pool'" placement="top" :visible-arrow="false" :popper-options="{boundariesElement:'body'}" popper-class="candidate-collect-popper" class="option-clollect-popper">
                                    <span>
                                        <font-icon class="candidate-icon isCollected" href="#icon-a-rencaiyishoucang" v-if="isCollected"></font-icon>
                                        <font-icon class="candidate-icon" href="#icon-shoucang_fill" v-else></font-icon>
                                        <span class="text">{{isCollected? '已收藏' : '收藏'}}</span>
                                    </span>
                                </el-tooltip>
                            </span>
                            <div class="collect-lable-popup" v-show="ifCollectPopupShow" v-loading="collectLoading">
                                <span class="arrow-up"></span>
                                <p><i class="icon-agree icon-font"></i></p>
                                <p>已收藏</p>
                                <p class="text-grey">添加标签，轻松分类人才</p>
                                <div class="label-lists">
                                    <span
                                        v-for="(item, index) in candidateLabels"
                                        :key="index"
                                        v-text="item.name"
                                        :class="['label-item', {active: item.isSelected}]"
                                        :title="item.name"
                                        @click="onLabelClick"
                                        :isselected="item.isSelected"
                                    >
                                    </span>
                                </div>
                                <div class="add-new-label" v-show="!isLabelInputVisible" @click="setLabelInputVisible">
                                    <i class="icon-font icon-tianjia"></i>
                                    添加新标签
                                </div>
                                <div class="add-label-input" v-show="isLabelInputVisible">
                                    <input type="text" placeholder="输入新标签" id="newLabelName" maxlength="10" @keydown="onLabelInputKeydown">
                                    <i class="icon-font icon-rec-add icon-add-marign" @click="addNewLabel"></i>
                                </div>
                            </div>
                        </div>
                        <div class="option-btn wx-code" v-if="(isFirmResume || isCloudRecommendation) && longUrl && !isFromAuthorization" @click="handleShare">
                            <font-icon class="candidate-icon" href="#icon-gongjulan-yaoqinghaoyou"></font-icon>
                            <span>分享</span>
                        </div>
                        <div class="option-btn resume-edit-btn" @click="onResumeEdit" v-if="!isCloudRecommendation && !isFromAuthorization">
                            <font-icon class="candidate-icon" href="#icon-edit1"></font-icon>
                            <span class="edit-text">编辑</span>
                        </div>
                        <div class="option-btn wx-code hide-resume-icon" v-if="isFirmResume && userInfo.isCFUser && userInfo.isAdministrator && !isFromAuthorization && isHiding" @click="handleCancelHideResume">
                            <font-icon class="candidate-icon" href="#icon-chakan"></font-icon>
                            <span>显示</span>
                        </div>
                        <div class="option-btn wx-code hide-resume-icon" v-if="isFirmResume && userInfo.isCFUser && userInfo.isAdministrator && !isFromAuthorization && !isHiding" @click="handleHideResume">
                            <font-icon class="candidate-icon" href="#icon-chakan"></font-icon>
                            <span>隐藏</span>
                        </div>
                    </div>
                    <div class="btn-list" :class="candidateDetailData.isFromAuthorization ? 'btn-list-authorization' : ''">
                        <div class="btn-list-item">
                            <div class="btn btn-hollow" @click="onShowRemindMeDialog">
                                <font-icon href="#icon-xiaoxizhongxin"></font-icon>
                                <span>添加事项提醒</span>
                            </div>
                            <div class="btn btn-hollow" @click="communicationJobs" v-if="candidateDetailData.isFirm">
                                <font-icon href="#icon-time1"></font-icon>
                                <span>加入待沟通</span>
                            </div>
                            <div
                                class="btn btn-hollow btn-resume-grab"
                                v-loading="addCompanyLoading"
                                @click="addToCompany(candidateDetailData.resumeGrabType)"
                                v-else
                            >
                                <font-icon href="#icon--shangchuan"></font-icon>
                                <span>{{ candidateDetailData.resumeGrabType == 2 || candidateDetailData.resumeGrabType === 0 ? '更新企业库简历':'导入企业库' }}</span>
                            </div>
                            <div class="btn btn-hollow" @click="handleAddTrackingList(candidateDetailData.isAddToTrackingList)" v-loading="addTrackingListLoading">
                                <font-icon href="#icon-user1"></font-icon>
                                <span>{{ candidateDetailData.isAddToTrackingList ? '移除Tracking List' : '加入Tracking List' }}</span>
                            </div>
                            <div v-if="!candidateDetailData.isFromAuthorization" class="btn btn-hollow" @click="onCandidateArticleEdit">
                                <font-icon class="candidate-icon" href="#icon-edit1"></font-icon>
                                <span>编辑简历</span>
                            </div>
                            <div class="recommend-btn" @click="recommendJobs">推荐</div>
                            <div class="inquire-btn" @click="inquiryShow">添加寻访</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 新一线机会弹窗 -->
        <new-first-city-dialog
            ref="newFirstCityDialog"
            :tbdResumeId="resumeId"
            :candidate-id="candidateId" 
            :newCityExpectationsInfo="newCityExpectationsInfo"
            @refresh-new-city-expectations="refreshNewCityExpectations"
        ></new-first-city-dialog>
        <!-- 新一线添加、修改日志弹窗 -->
        <edit-new-city-logs-dialog
            ref="editNewCityLogsDialog"
            :tbdResumeId="resumeId"
        ></edit-new-city-logs-dialog>

        <inquiry-dialog
            ref="inquiryDialog"
            :source-from="5"
            @refresh-detail="refreshdetail"
            @update-item="RecordListRefresh"
        ></inquiry-dialog>
        <add-communication-dialog 
            ref="addCommunicationDialog" 
            @update-item="handelTabRefresh"
        ></add-communication-dialog>
        <import-result-dialog
            ref="importResultDialog"
            @import-confirm="handleImportConfirm"
            @import-then-compare="goToCompare"
        ></import-result-dialog>
        <create-notice-dialog 
            ref="createNoticeDialog"
        ></create-notice-dialog>
        <recommend-dialog 
            ref="recommendDialog" 
            :type="0"
        ></recommend-dialog>

        <mail-dialog 
            ref="mailDialog" 
            :candidate-id="candidateId" 
            :candidate-email="email" 
            :candidate-name="realName"
        ></mail-dialog>

        <base-info-edit-dialog
            ref="baseInfoEditDialog"
            :isPersonalResume="!isFirmResume"
            @candidate-detail-refresh="$emit('candidate-detail-refresh')"
            @apply-view-success="handleApplyViewSuccess"
        ></base-info-edit-dialog>
        <el-dialog
            title="提示"
            :visible.sync="unlockDialog"
            width="520px"
            v-loading="unlockLoading"
            custom-class="unlock-info-dialog">
            <div class="dialog-content">
                <p class="dialog-content-tips">候选人还在下面客户的入职保证期阶段，确定解除联系方式的锁定?</p>
                <div class="customer-list">
                    <span
                        class="list-item"
                        v-for="(customer, index) in customerList"
                        :key="index"
                    >
                        {{`${customer.company} ${customer.jobName}[${customer.locationName}]`}}
                    </span>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="unlockDialog = false">取消</el-button>
                <el-button type="primary" @click="handleUnlock">确定解锁</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="提示"
            :visible.sync="markInvalidDialog"
            width="500px"
            v-loading="markInvalidLoading">
            <div class="dialog-content">
                确定将该{{contactText}}标记为无效吗？
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="markInvalidDialog = false">取消</el-button>
                <el-button type="primary" @click="handleMarkInvalid">确定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="提示"
            :visible.sync="markValidDialog"
            width="500px"
            v-loading="markValidLoading">
            <div class="dialog-content">
                确定激活该{{contactText}}吗？
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="markValidDialog = false">取消</el-button>
                <el-button type="primary" @click="handleMarkValid">确定</el-button>
            </span>
        </el-dialog>

        <email-share 
            ref="emailShare" 
            :recommendationId="recommendationid"
        ></email-share>

        <el-dialog
            custom-class="share-dialog"
            title="分享"
            :visible.sync="shareDialogShow"
            width="500px">
            <div class="dialog-content">
                <div @click="showShareToImDialog">
                    <img :src="require('#/assets/images/instantMessage/private_message.png')" />
                    私信和群分享
                </div>
                <div @click="showShareToWechatDialog">
                    <img :src="require('#/assets/images/instantMessage/wechat.png')" />
                    微信好友
                </div>
            </div>
        </el-dialog>
        <el-dialog
            custom-class="share-to-wechat-dialog"
            title="分享"
            :visible.sync="shareToWechatDialogShow"
            width="500px"
            :before-close="handleCloseshareToWechatDialog">
            <div class="dialog-content">
                <img width="118" height="118" :src="qrcodeUrl" />
                <p>打开微信扫一扫</p>
                <el-button type="primary" round @click="handleCloseshareToWechatDialog">完成</el-button>
            </div>
        </el-dialog>
        <el-dialog
            custom-class="hide-resume-dialog"
            title="选择隐藏时间"
            :visible.sync="hideResumeDialogShow"
            width="500px">
            <div class="dialog-content">
                <p class="hide-resume-dialog-text">时间</p>
                <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    start-placeholder="开始日期"
                    range-separator="-"
                    end-placeholder="结束日期"
                    :editable="false"
                    align="right"
                    value-format="yyyy-MM-dd"
                    @change="handleHideDate"
                    :clearable="false"
                    :picker-options="expireTimeOption"
                    popper-class="performance-data-range"
                ></el-date-picker>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelHideResume">取消</el-button>
                <el-button type="primary" @click="confirmHideResume" v-loading="hideResumeLoading">确定</el-button>
            </span>
        </el-dialog>
        <label-management ref="labelManagement" @labelManagementClose="labelManagementClose"></label-management>
    </div>
</template>

<script>
    // import RemindMeDialog from '../remind-me-dialog/index'; ?????

    import moment from 'moment';
    import ClipboardJS from 'clipboard';    //文字复制插件
    import { candidate as candidateDetailConfig } from '#/js/config/api.json';
    import genderMap from '#/js/config/gender.json';
    import { errorMessage } from '#/js/util/tools.js';
    import EmailShare from '../../component/candidateEmailShareDialog.vue';
    import MailDialog from '#/component/base/mail-dialog/index';
    import PhoneCall from '#/component/common/phone-call-new.vue';
    import BaseInfoEditDialog from '#/component/common/dialog/baseInfoEditDialog.vue';
    import CandidateLabels from '#/views/candidate-detail/component/candidateLabels.vue';
    import LabelManagement from '#/component/common/dialog/label-management.vue';
    import Avatar from '#/component/common/avatar.vue';
    import emitter from '#/js/mixins/emitter.js';
    import resumeMergeService from '#/js/service/resumeMerge.js';
    import talentPoolService from '#/js/service/talentPoolService.js';
    import { getDegreeTextById } from '#/js/filters/degreeInfo.js';
    import newFirstCityDialog from './new-first-city-dialog.vue';
    import editNewCityLogsDialog from './edit-new-city-logs-dialog.vue';
    import InquiryDialog from '#/component/common/dialog/inquiryDialog.vue';
    import AddCommunicationDialog from '#/component/common/dialog/add-communication-dialog.vue';
    import ImportResultDialog from '#/component/common/dialog/importResultDialog.vue';
    import CreateNoticeDialog from '#/component/common/dialog/createNoticeDialog.vue'
    import RecommendDialog from '#/component/common/dialog/recommendDialog.vue';
    import newFirstCityData from '#/js/config/newFirstCityData.json';

    export default {
        components: {
            newFirstCityDialog,
            editNewCityLogsDialog,
            InquiryDialog,
            AddCommunicationDialog,
            ImportResultDialog,
            CreateNoticeDialog,
            RecommendDialog,

            // RemindMeDialog,
            MailDialog,
            BaseInfoEditDialog,
            // CandidateLabelCloud,
            PhoneCall,
            // Modal,
            EmailShare,
            CandidateLabels,
            Avatar,
            LabelManagement
        },
        mixins: [
            emitter,
        ],
        data() {
            return {
                newFirstCityData: newFirstCityData,

                addTrackingListLoading: false,
                addCompanyLoading: false,

                candidateDetail: {},
                callTo: this.$store.state.candidateDetail.candidateInfo.isRecommendation ? 3 : 2,
                candidateLabels: [],
                isLabelInputVisible: false,
                // init value from props
                isJobHunting: false,
                realName: '',
                birthday: '',
                gender: '',
                mobile: '',
                email: '',
                wechat: '',
                province: '',
                provinceName: '',
                city: '',
                cityName: '',
                company: '',
                candidateTitle: '',
                degreeInfo: '',
                yearOfExperience: '',
                school: '',
                major: '',
                degree: '',
                longUrl: '',
                isCollected: false,
                isFromAuthorization: false,
                proportion: 0,
                customerList: [],
                ifCollectPopupShow:false,
                detailLoading:false,
                collectLoading:true,
                unlockDialog:false,
                unlockLoading:false,
                mobileStatus: 1,
                emailStatus: 1,
                wechatStatus: 1,
                viewMobileLoading: false,
                viewEmailLoading: false,
                viewWechatLoading: false,
                hasCandidateLabels: false,
                updateTime:'',
                creatorRealName:'',
                contactType: "",
                markInvalidDialog: false,
                markInvalidLoading: false,
                markValidDialog: false,
                markValidLoading: false,
                shareDialogShow: false,
                shareToWechatDialogShow: false,
                hideResumeDialogShow: false,
                dateRange: '',
                expireTimeOption: {
                    disabledDate(date) {
                        return (date.getTime() < Date.now()) && !moment(date).isSame(new Date(), 'day');
                    },
                },
                hideResumeLoading: false,
                isHiding: false,
                hidingStartTime: '',
                hidingEndTime: '',
                customerDepartmentName: '',
                qrcodeUrl: '',

                currentUuid: '',
            }
        },
        props: {
            newCityExpectationsInfo: Object,
            candidateDetailData: Object,
            candidateId: String,
            resumeId:String,
            isFirmResume:Boolean,
            completionPercent:String,
            isCloudRecommendation: {
                type: Boolean,
                default: false
            },
            recommendationid: {
                type: String,
                default: ''
            },
            completionPercent: String,
            existedResumeId: String,
            shareData: Object
        },
        computed: {
            userInfo() {
                return this.$store.state.user.userInfo;
            },
            isCFuser(){
                return this.userInfo.isCFUser;
            },
            lockStatus() {
                return this.$store.state.candidateDetail.lockStatus;
            },
            token(){
                return this.$store.state.verificationToken.verificationToken;
            },
            canCall(){
                let regexMobileInternal = /^(1[3-9])\d{9}$/;
                let isValidMobileInternal = regexMobileInternal.test(this.mobile);
                return isValidMobileInternal || this.isFromAuthorization;
            },
            enableHideMobile(){
                let regexMobileInternal = /^(1[3-9])\d{9}$/;
                let regexMobileExternal = /^(\+|00)\d{8,15}$/;
                let isValidMobileInternal = regexMobileInternal.test(this.mobile) || regexMobileExternal.test(this.mobile);
                return !isValidMobileInternal && this.isFromAuthorization;
            },
            contactText() {
                let list = {
                    mobile: '手机号码',
                    email: '邮箱',
                    wechat: '微信'
                };
                return list[this.contactType];
            },

            newCityExpectationsText() {
                let locations = [];
                this.newFirstCityData && this.newFirstCityData.forEach(item => {
                    this.newCityExpectationsInfo && 
                    this.newCityExpectationsInfo.locations && 
                    this.newCityExpectationsInfo.locations.forEach(el => {
                        if(el == item.value) {
                            locations.push(item.label);
                        }
                    })
                })
                let locationsText = locations.join('、');
                // console.log(locationsText);

                let expectationType = this.newCityExpectationsInfo?.expectationType;
                let address = this.newCityExpectationsInfo?.address ? this.newCityExpectationsInfo?.address.replace(/\s/g, '、') : '';
                if( expectationType == '1') {
                    return '不考虑';
                } else {
                    if(expectationType == 3) {
                        return '现在考虑' + locationsText + `${address ? '及'+address : ''}`;
                    } else if(expectationType == 5) {
                        return '半年内考虑' + locationsText + `${address ? '及'+address : ''}`;
                    } else if(expectationType == 7) {
                        return '1年内考虑' + locationsText + `${address ? '及'+address : ''}`;
                    } else if(expectationType == 9) {
                        return '1-3年考虑' + locationsText + `${address ? '及'+address : ''}`;
                    } else if(expectationType == 11) {
                        return '3年以上考虑' + locationsText + `${address ? '及'+address : ''}`;
                    }
                }
            },
        },
        filters: {
            birthdayToAge(birthday) {
                return ((new Date).getFullYear() - birthday.slice(0, 4)) + '岁';
            }
        },
        mounted () {
            let _self = this;
           
            // this.remindMeDialog = this.$refs.remindMeDialog;
            this.mailDialog = this.$refs.mailDialog;
            // this.candidateLabelCloud = this.$refs.candidateLabelCloud;
            this.baseInfoEditDialog = this.$refs.baseInfoEditDialog;
            this.createNoticeDialog = this.$refs.createNoticeDialog;
            this.recommendJobDialog = this.$refs.recommendDialog;

            this.initEvent();

            this.$on('showLabelManagement', this.showLabelManagement);
        },
        methods: {
            addNewFirstCity() {
                this.$refs.newFirstCityDialog.show('create');
            },
            editNewFirstCity() {
                this.$refs.newFirstCityDialog.show('update');
            },
            viewLogs() {
                console.log(this.$refs.editNewCityLogsDialog);
                this.$refs.editNewCityLogsDialog.show();
            },

            refreshNewCityExpectations(resumeId) {
                this.$emit('candidate-new-city-expectations-refresh', resumeId);
            },

            refreshdetail(updateTimeLine){
                this.$emit('candidate-detail-refresh', updateTimeLine);
            },
            RecordListRefresh(resume){
                this.$emit('candidate-search-record-refresh', resume);
            },
            handelTabRefresh(resume){
                this.$emit('handel-tab-refresh', resume);
            },
            handleImportConfirm(){
                this.$emit('import-confirm')
            },
            goToCompare(resume) {
                this.addCompanyLoading = true;
                if(resume && resume.repeatResumeId) {
                    window.open(`/Headhunting/MyCompany.html#/candidate/updatecheck?resumeId=${this.candidateDetailData.tbdResumeId}&originalResumeId=${resume.repeatResumeId}`, '_blank');
                    // window.open(`/#/candidate/updatecheck?resumeId=${this.candidateDetailData.tbdResumeId}&originalResumeId=${resume.repeatResumeId}`, '_blank');
                } else {
                    this.$emit('get-exist-id', () => {
                        window.open(`/Headhunting/MyCompany.html#/candidate/updatecheck?resumeId=${this.candidateDetailData.tbdResumeId}&originalResumeId=${this.existedResumeId}`, '_blank');
                        // window.open(`/#/candidate/updatecheck?resumeId=${this.candidateDetailData.tbdResumeId}&originalResumeId=${this.existedResumeId}`, '_blank');
                    })
                }
                this.addCompanyLoading = false;
            },


            inquiryShow(){
                let resumeId = this.candidateDetailData.tbdResumeId;
                this.$refs.inquiryDialog.show({
                    resumeId: resumeId,
                    candidateId: this.candidateDetailData.id,
                    isFirmResume: this.candidateDetailData.isFirm,
                    company: this.candidateDetailData.company,
                    title: this.candidateDetailData.title,
                    workExperiences: this.candidateDetailData.workExperiences,
                    isFromAuthorization: this.candidateDetailData.isFromAuthorization,
                    
                    customerId: this.candidateDetailData.customerId,
                    customerDepartmentId: this.candidateDetailData.customerDepartmentId,
                    customerDepartmentName: this.candidateDetailData.customerDepartmentName,
                });
            },
            handleAddTrackingList(isTrackingList){
                this.addTrackingListLoading = true;
                if(isTrackingList){
                    talentPoolService.removeTrackingList({
                        sourceFrom: 4,
                        resumeIds: this.candidateDetailData.tbdResumeId
                    }).then((res) => {
                        if(res.successCount){
                            shortTips('移除trackinglist成功');
                            _tracker.track('removeTrackingList',JSON.stringify({
                                resumeId: [this.candidateDetailData.tbdResumeId],
                                isFirmResume: this.candidateDetailData.isFirm,
                                isBatch: false,
                                form: 'candidateDetail'
                            }));
                            this.candidateDetailData.isAddToTrackingList = false;
                        }else{
                            shortTips('移除失败，请稍后尝试！');
                        }
                    }).catch((err) => {
                        console.log(err);
                    }).finally(()=>{
                        this.addTrackingListLoading = false;
                    });
                }else{
                    talentPoolService.addToTrackingList({
                        sourceFrom: 4,
                        resumeIds: this.candidateDetailData.tbdResumeId
                    }).then((res) => {
                        if(res.successCount) {
                            _tracker.track('addTrackingList',JSON.stringify({
                                resumeId: this.candidateDetailData.tbdResumeId,
                                from: 'candidateDetail'
                            }));
                            shortTips('已成功加入trackinglist');
                            this.candidateDetailData.isAddToTrackingList = true;

                            // 加入trackinglist之后弹出添加标签的弹窗
                            this.$refs.candidateLabels.visible = true;
                        }
                        if(res.existCount){
                            shortTips('该人才已存在trackinglist');
                            this.candidateDetailData.isAddToTrackingList = true;
                        }
                        if(res.failCount){
                            shortTips('加入失败，请稍后尝试！');
                        }
                    }).catch((err) => {
                        console.log(err);
                    }).finally(()=>{
                        this.addTrackingListLoading = false;
                    });
                }
            },
            communicationJobs() {
                this.$refs.addCommunicationDialog.show({
                    candidateId: this.candidateDetailData.id
                });
            },
            addToCompany(resumeGrabType) {
                if(this.isCFuser){
                    // if(resumeGrabType ==0){
                    //     return ;
                    // }
                    if(resumeGrabType == null || resumeGrabType == 1){
                        this.$confirm('确定导入到企业人才库？', '提示', {
                            showClose: false,
                            cancelButtonText: '取消',
                            confirmButtonText: '确定'
                        }).then(() => {
                            this.addCompanyLoading = true;
                            this.importToFirm().then((res) => {
                                this.addCompanyLoading = false;
                                if(res) {
                                    this.$refs.importResultDialog.show(res);
                                }
                            }).catch((err) => {
                                this.addCompanyLoading = false;
                                console.log(err);
                            });
                        }).catch(() => {});
                    // }else if(resumeGrabType==2){
                    } else {
                        this.goToCompare();
                    }
                }else{
                    this.$confirm('确定导入到企业人才库？', '提示', {
                            showClose: false,
                            cancelButtonText: '取消',
                            confirmButtonText: '确定'
                        }).then(() => {
                            this.addCompanyLoading = true;
                            this.importToFirm().then((res) => {
                                this.addCompanyLoading = false;
                                if(res) {
                                    this.$refs.importResultDialog.show(res);
                                }
                            }).catch((err) => {
                                this.addCompanyLoading = false;
                                console.log(err);
                            });
                        }).catch(() => {});
                }
            },
            onShowRemindMeDialog(){
                //this.remindMeDialog.show();
                //这里为保证与工作台的新增提醒的传值一致
                let resume = {
                    resumeListItem:{
                        resumeId: this.candidateDetailData.tbdResumeId
                    }
                }
                this.createNoticeDialog.showDialog(resume);
            },
            recommendJobs() {
                if(this.candidateDetailData.isFirm){
                    this.$route.params.isFirm = this.$route.query.originIsFirm ? this.$route.query.originIsFirm : true;
                    this.recommend(this.candidateDetailData.id);
                }else{
                    if(this.candidateDetailData.resumeGrabType==1 || !this.existedResumeId){
                        this.$route.params.isFirm = false;
                        this.$confirm('简历需要导入企业库后才能完成推荐操作，是否将简历导入企业库并继续推荐？', '推荐提示', {
                            showClose: false,
                            customClass: 'comfirm-dialog-class',
                            cancelButtonText: '取消',
                            confirmButtonText: '导入并推荐候选人'
                        }).then(() => {
                            this.importToFirm().then((res) => {
                                if(res) {
                                    let importResult = res;
                                    if(importResult.failCount == 0) {
                                        this.firstLoad = true;
                                        this.recommend(res.list[0].id);
                                        this.$emit('get-candidate-detail');
                                    }else{
                                        let errorMessage = '';
                                        importResult.list.forEach(item => {
                                            if(!item.successed) {
                                                errorMessage = item.errorMessage;
                                                return;
                                            }
                                        });
                                        shortTips(errorMessage);
                                    }
                                }
                            }).catch((err) => {
                                console.log(err);
                            });
                        }).catch(() => {});
                    }else{
                        this.$route.params.isFirm = true;
                        this.$confirm('企业库中已有该候选人，请使用企业库简历进行推荐', '推荐提示', {
                            showClose: false,
                            customClass: 'comfirm-dialog-class',
                            cancelButtonText: '取消',
                            confirmButtonText: '打开企业库简历'
                        }).then(() => {
                            this.$emit('get-exist-id', (existResumeId) => {
                                window.open(`/Headhunting/MyCompany.html#/candidateDetail/${existResumeId}?originIsFirm=${this.$route.params.isFirm}`, '_blank');
                                // window.open(`/#/candidateDetail/${existResumeId}?originIsFirm=${this.$route.params.isFirm}`, '_blank');
                            })
                        }).catch(() => {});
                    }
                }
            },
            recommend(id){
                // let recommendUrl = placeholder(candidateDetailConfig.url_recommend_job, id);
                let recommendUrl = candidateDetailConfig.url_recommend_job.replace(/%p/, id);
                this.recommendJobDialog.show(id);
            },
            importToFirm() {
                let resumeIds = {tbdResumeIds:[this.candidateDetailData.tbdResumeId]};
                return _request({
                    url: "/MyCompany/Candidates/Personal/ImportToFirm",
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: resumeIds
                });
            },
            onCandidateArticleEdit(){
                let url = '';
                if(this.candidateDetailData.isFirm){
                    // url = `/Headhunting/MyCompany.html?type=1&candidateId=${this.candidateDetailData.id}#/resumeUpload/singleUpload`;
                    url = `/Headhunting/MyCompany.html?type=1&candidateId=${this.candidateDetailData.id}#/resumeUpload/singleUpload`;
                }else{
                    // url = `/Headhunting/MyCompany.html?type=0&candidateId=${this.candidateDetailData.tbdResumeId}#/resumeUpload/singleUpload`;
                    url = `/Headhunting/MyCompany.html?type=0&candidateId=${this.candidateDetailData.tbdResumeId}#/resumeUpload/singleUpload`;
                }
                window.open(url,'_blank');
            },

            initEvent(){
                let _self = this;

                $(document).click(function(ev){
                    let $target = $(ev.target);
                    if($target.closest('.collect-btn').length == 0){
                        _self.ifCollectPopupShow = false;
                    }
                    ev.stopPropagation();
                });
            },
            formatterQrcodeUrl(url) {
                return `${_host.portal}/Sharing/QRCode?url=` + encodeURIComponent(`${_host.h5_page}/candidateShare.html?candidateId=${candidateId}`);
                return _host.portal + url;
            },
            handleCollectLabel(){
                if(this.isCollected){
                    this.getCandidateLabels();
                }
            },
            handleOptXhrValue(data){
                let res = null;

                res = JSON.parse(JSON.stringify(data));

                this.candidateDetail = data;

                this.qrcodeUrl = `${_host.portal}/Sharing/QRCode?url=` + encodeURIComponent(`${_host.h5_page}/candidateShare.html?candidateId=${data.tbdResumeId || data.id}`);

                res.candidateTitle = res.title;
                res.email = res.email && res.email!='xxx@xx.com' ? res.email : '未知';
                res.wechat = res.wechat && res.wechat!='xxxxxx' ? res.wechat : '未知';
                res.cityName = res.cityName ? res.cityName : '未知城市';
                res.gender = genderMap[res.gender] || '未知';
                res.yearOfExperience = res.yearOfExperience ? res.yearOfExperience :'未知';
                res.updateTime = res.updated;
                if(res.hidingStartTime && res.hidingEndTime) {
                    res.dateRange = [moment(res.hidingStartTime).format('YYYY-MM-DD'), moment(res.hidingEndTime).format('YYYY-MM-DD')];
                }

                for(let prop in res){
                    if(prop !== 'resumeId') {
                        this[prop] = (res[prop] == null ? '' : res[prop]);
                    }
                }
                this.$nextTick(() => {
                    if(this.$refs.copyWechatBtn) {
                        const copyLink = new ClipboardJS(this.$refs.copyWechatBtn.$el, {
                            text: () => {
                                return this.wechat
                            }
                        });
                        copyLink.on('success', (res) => {
                            shortTips("复制成功");
                        });
                        copyLink.on('error', (err) => {
                            console.log(err)
                            shortTips("复制失败，请手动复制。");
                        });
                    }
                });
            },
            handelRecordRefresh(){
                this.$emit('candidate-search-record-refresh');
            },
            onResumeEdit(){
                this.baseInfoEditDialog.show({
                    ...this.candidateDetail,
                    isFirmResume: this.isFirmResume
                });
            },

            onShowMailDialog(){
                this.mailDialog.show();
            },

            handleShare() {
                this.shareDialogShow = true;
            },

            handleHideResume() {
                this.hideResumeDialogShow = true;
            },

            handleCancelHideResume() {
                let self = this;
                this.detailLoading = true;
                resumeMergeService.relieveHide({
                    tbdResumeId: self.candidateDetail.tbdResumeId
                }).then(res =>{
                    self.isHiding = false;
                    self.dateRange = '';
                    self.hidingStartTime = '';
                    self.hidingEndTime = '';
                    shortTips('解除隐藏成功!');
                }).finally(() => {
                    self.detailLoading = false;
                })
            },

            handleHideDate(value){
            },

            cancelHideResume() {
                if(this.hidingStartTime && this.hidingEndTime) {
                    this.dateRange = [moment(this.hidingStartTime).format('YYYY-MM-DD'), moment(this.hidingEndTime).format('YYYY-MM-DD')];
                }else {
                    this.dateRange = '';
                }
                this.hideResumeDialogShow = false;
            },

            confirmHideResume() {
                if(this.hideResumeLoading) {
                    return false;
                }
                if(!this.dateRange) {
                    shortTips('请选择隐藏时间');
                    return false;
                }
                let self = this;
                let startTime = this.dateRange[0];
                let endTime = this.dateRange[1];
                let isUpdate = (this.hidingStartTime && this.hidingEndTime) ? true : false;

                this.hideResumeLoading = true;
                resumeMergeService.hideResume({
                    tbdResumeId: self.candidateDetail.tbdResumeId,
                    startTime: startTime,
                    endTime: endTime
                }, isUpdate).then(res =>{
                    shortTips(isUpdate ? '更新隐藏时间成功!' : '设置隐藏时间成功!');
                    self.hideResumeDialogShow = false;
                    self.hidingStartTime = startTime;
                    self.hidingEndTime = endTime;
                    self.$emit('candidate-detail-refresh');
                }).finally(() => {
                    self.hideResumeLoading = false;
                })
            },

            showShareToImDialog() {
                this.shareDialogShow = false;
                let shareData = Object.assign({type: 'candidateShare'}, this.shareData);
                this.dispatch('App', 'showShareToImDialog', shareData);
            },

            showShareToWechatDialog() {
                this.shareToWechatDialogShow = true;
            },

            handleCloseshareToWechatDialog() {
                this.shareDialogShow = false;
                this.shareToWechatDialogShow = false;
            },

            showCollectPopup(vm){
                if(this.isCollected){
                    if(!this.hasCandidateLabels){
                        this.getCandidateLabels();
                        this.hasCandidateLabels = true;
                    }
                    this.ifCollectPopupShow = true;
                }
            },

            onCandidateCollect(){
                let resumeId = this.candidateDetail.tbdResumeId;
                this.collectLoading=true;
                if(this.isCollected) {
                    _request({
                        url: `/Resume/${resumeId}/UnCollect`,
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).then((res) => {
                        this.isCollected = false;
                        this.getCandidateLabels();
                        this.ifCollectPopupShow = false;
                        shortTips('取消收藏成功');
                        this.collectLoading=false;
                        this.hasCandidateLabels = false;
                   }).catch((err) => {
                        console.log(err);
                        this.collectLoading=false;
                    })
                } else {
                    _request({
                        url: `/Resume/${resumeId}/Collect`,
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).then((res) => {
                        this.isCollected = true;
                        this.ifCollectPopupShow = true;
                        shortTips('收藏成功');
                        this.collectLoading=false;
                    }).catch((err) => {
                        this.collectLoading=false;
                        console.log(err);
                    })
                }
            },

            onLabelClick(vm,ev){
                if(vm.currentTarget.attributes.isselected){
                    this.removeLabel(vm.currentTarget.attributes.title.value);
                }else{
                    this.createLabel(vm.currentTarget.attributes.title.value);
                }
            },

            setLabelInputVisible(){
                this.isLabelInputVisible=true;
            },

            onLabelInputKeydown(vm,ev){
                if(vm.keyCode === 13){
                    this.addNewLabel(vm,ev);
                }
                return true;
            },

            addNewLabel(vm,ev){
                let $newLabelName = $('#newLabelName').val().trim();

                if(!$newLabelName){
                    shortTips('标签名不能为空');
                    return;
                }

                if($newLabelName.length>20){
                    shortTips('标签名不得超过20个字符');
                    return;
                }

                if (_isDangerousString($newLabelName)) {
                    shortTips('您输入的内容包含非法字符，请重新输入');
                    return;
                }
                this.createLabel($newLabelName);
            },

            createLabel(name){
                // let creatLabelUrl = placeholder(candidateDetailConfig.add_itap, this.resumeId);
                const creatLabelUrl = candidateDetailConfig.add_itap;
                
                _request({
                    url: creatLabelUrl,
                    method: "POST",
                    data: {
                        objectIds: this.resumeId,
                        tagsName: name
                    }
                }).then((res) => {
                    shortTips('添加成功');
                    $('#newLabelName').val('');
                    this.getCandidateLabels();
                    this.$refs.candidateLabels.getCandidateLabels();
                    this.$refs.candidateLabels.getResumeLabel();
                    // this.candidateLabelCloud.selectedList.fetch({reset:true});
                    // this.candidateLabelCloud.unselectedList.fetch({reset:true});
                }).catch(err => {
                    console.log(err);
                });
            },

            removeLabel(name){
                // let url = placeholder(candidateDetailConfig.remove_itap, this.resumeId , name);
                const url = candidateDetailConfig.remove_itap.replace(/%p/, this.resumeId).replace(/%p/, name);
                _request({
                    url: url,
                    method: 'DELETE'
                }).then(()=>{
                    this.getCandidateLabels();
                    this.$refs.candidateLabels.getCandidateLabels();
                    this.$refs.candidateLabels.getResumeLabel();
                    // this.candidateLabelCloud.selectedList.fetch({reset:true});
                    // this.candidateLabelCloud.unselectedList.fetch({reset:true});
                });
            },
            markInvalid(type) {
                this.contactType = type;
                if(this[type + 'Statue'] == 1) {
                    shortTips(`请先查看${this.contactText}`);
                    return;
                }
                this.markInvalidDialog = true;
            },
            handleMarkInvalid() {
                let urlList = {
                    mobile: `/Resume/${this.resumeId}/MobileInvalid?mobile=${this.mobile}`,
                    email: `/Resume/${this.resumeId}/EmailInvalid?email=${this.email}`,
                    wechat: `/Resume/${this.resumeId}/WechatInvalid?wechat=${this.wechat}`
                };
                let url = urlList[this.contactType];
                this.markInvalidLoading = true;
                _request({
                    url: url,
                    method: "POST",
                    data:{valid:false}
                }).then(res => {
                    this[this.contactType + 'Status'] = 4;
                    shortTips(`标记${this.contactText}失效成功`);
                    this.markInvalidDialog = false;
                    this.markInvalidLoading = false;
                }).catch(err => {
                    console.log(err);
                    this.markInvalidLoading = false;
                });
            },
            markValid(type){
                this.contactType = type;
                this.markValidDialog = true;
            },
            handleMarkValid(){
                let urlList = {
                    mobile: `/Resume/${this.resumeId}/MobileInvalid?mobile=${this.mobile}`,
                    email: `/Resume/${this.resumeId}/EmailInvalid?email=${this.email}`,
                    wechat: `/Resume/${this.resumeId}/WechatInvalid?wechat=${this.wechat}`
                };
                let url = urlList[this.contactType];
                this.markValidLoading = true;
                _request({
                    url: url,
                    method: "POST",
                    data:{valid:true}
                }).then(res => {
                    this[this.contactType + 'Status'] = 3;
                    shortTips(`${this.contactText}激活成功`);
                    this.markValidDialog = false;
                    this.markValidLoading = false;
                }).catch(err => {
                    this.markValidLoading = false;
                    console.log(err);
                });
            },
            applyView(type) {
                let list = {
                    1: 'Mobile',
                    2: 'Email',
                    3: 'Wechat'
                }
                const data = {
                    type: type,
                    __RequestVerificationToken: this.token
                };
                // let url = placeholder(candidateDetailConfig.url_log_view_contact, this.candidateId);
                const url = candidateDetailConfig.url_log_view_contact.replace(/%p/, this.candidateId);
                this[`view${list[type]}Loading`] = true;

                this.currentUuid = _generateUUID();
                _request({
                    url: url,
                    method: "POST",
                    data: data
                }).then(res => {
                    this[`view${list[type]}Loading`] = false;
                    this.$emit('operationEvent-update-success', list[type], res);

                    this.genCustomEvent(list[type], 'success');
                }).catch(err => {
                    this[`view${list[type]}Loading`] = false;
                    console.log(err);

                    this.genCustomEvent(list[type], 'fail', JSON.stringify(err) || '');
                });
            },
            handleApplyViewSuccess(vm, data) {
                this.$emit('operationEvent-update-success', vm, data);
            },
            getCandidateLabels(){
                let url = `/Resume/${this.resumeId}/Tags`
                this.collectLoading = true;
                _request({
                    url: url,
                    method: "GET"
                }).then(res => {
                    this.candidateLabels = res;
                }).catch(err => {
                    console.log(err);
                }).finally(()=>{
                    this.collectLoading = false;
                });
            },
            unlockInfo() {
                if(!this.userInfo.isAdministrator) {
                    return ;
                }
                this.getCustomerList();
                this.unlockDialog=true;
            },
            handleUnlock() {
                this.unlockLoading=true;

                _request({
                    method: 'GET',
                    url: candidateDetailConfig.url_unlock.replace(/%p/, this.candidateId),
                    throwBusinessError: true
                }).then(res =>{
                    this.$emit('candidate-unlock', {lock: true});
                    this.unlockDialog = false;
                }).finally(() =>{
                    this.unlockLoading = false;
                })
            },
            getCustomerList() {
                // let onBoardLockListUrl = placeholder(candidateDetailConfig.url_on_bord_lock_list, this.candidateId);
                const onBoardLockListUrl = candidateDetailConfig.url_on_bord_lock_list.replace(/%p/, this.candidateId);
                _request({
                    method: 'GET',
                    url: onBoardLockListUrl,
                }).then(res =>{
                    this.customerList = res || [];
                }).finally(() =>{
                    this.unlockLoading = false;
                })
            },
            onEmailShare(){
                if(this.recommendationid){
                    this.$refs.emailShare.show(this.recommendationid,this.realName);
                }
            },
            onPrint(){
                let prnhtml= $('.original-resume').html();
                let page = window.open('', '_blank'); // 打开一个空表窗口，用于打印
                page.document.write(prnhtml); // 打印页面的内容
                page.print(); // 打印
            },
            showLabelManagement() {
                this.$refs.labelManagement.show();
            },
            labelManagementClose() {
                this.$refs.candidateLabels && this.$refs.candidateLabels.getResumeLabel();
            },
            getEData(type) {
                // this.currentUuid = _generateUUID();
                // 1: 'Mobile',
                //     2: 'Email',
                //     3: 'Wechat'
                return `{"candidate_contact_type": "${type}", "candidate_contact_trace_id": "${this.currentUuid}"}`;
            },
            // 生成默认事件
            genCustomEvent(type, status, reason='') {
                let json = {
                    eventId: `E_candidate_contact_click_result`,
                    extendProperties: {
                        element_spm: "P_candidate_detail@A_candidate_detail_top@L_candidate_contact",
                        // candidate_contact_trace_id: _generateUUID(),
                        candidate_contact_trace_id: this.currentUuid,
                        candidate_contact_type: type,
                        candidate_contact_result_status: status,
                        candidate_contact_result_fail_reason: reason,
                    },
                }
                
                window.BazaCommonTrack.generateCustomEvent(json);
            },
        }
    }
</script>

<style lang="scss">
    @import './candidate-base-info-store.scss';

    .unlock-info-dialog {
        max-height: 400px;
        .dialog-content {
            &-tips {
                color: #444;
                font-size: 16px;
                margin: 0;
            }

            .customer-list {
                max-height: 150px;
                min-height: 70px;
                overflow-y: auto;
                padding: 10px 0;

                &-item {
                    display: block;
                    height: 30px;
                    line-height: 30px;
                }

                .list-item {
                    display: block;
                    color: #666;
                    font-size: 14px;
                    line-height: 30px;
                }
            }
        }
    }

    .collect-lable-popup .add-label-input .icon-add-marign{
        margin-left: 5px;
    }
    .candidate-collect-popper.el-tooltip__popper{
        padding: 10px 20px;
        font-size: 14px;
        color: #666;
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.30);
        margin-bottom: 3px;
    }
    .invalid-popper.el-tooltip__popper{
        font-size: 14px;
        color: #666;
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.30);
        padding: 10px 20px;
    }
    .candidate-active-popper.el-popover{
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.30);
        border-radius: 4px;
        padding: 20px;
        font-size: 14px;
        color: #666666;
        .title{
            font-size: 16px;
            color: #444444;
            text-indent: 10px;
            position: relative;
            &::before{
                display: block;
                content: "";
                background-color: #00BD9C;
                width: 4px;
                height: 12px;
                position: absolute;
                top: 3px;
            }
        }
        .el-input{
            width: 182px;
            .el-input__inner{
                background: #F8F8F8;
                border-radius: 4px;
                height: 28px;
                line-height: 28px;
                padding-left: 10px;
            }
            .el-input__suffix{
                top: -5px;
            }
            &.email-input{
                width: 225px;
            }
        }
        .el-button{
            font-size: 14px;
            color: #FFFFFF;
            background: #38BC9D;
            border-radius: 4px;
            width: 48px;
            padding: 5px 10px;
            height: 28px;
            margin-left: 5px;
            min-width: 0px;
        }
    }

    .share-dialog {
        .el-dialog__body {
            padding: 0;
        }
        .el-dialog__header {
            border: 0 none;
        }
        .dialog-content >div {
            height: 72px;
            line-height: 72px;
            font-size: 14px;
            color: #444;
            padding-left: 20px;
            cursor: pointer;
            overflow: hidden;
            &:nth-last-of-type(1) {
                border-radius: 0 0 6px 6px;
            }

            &:hover, &:focus, &:active {
                background-color: #ebf8f5;
            }

            img {
                display: inline-block;
                width: 40px;
                height: 40px;
                margin-right: 14px;
            }

            &:first-child {
                border-bottom: 1px solid #F0F0F2;
            }
        }
    }
    .share-to-wechat-dialog {
        .el-dialog__header {
            border: 0 none;
        }
        .dialog-content {
            text-align: center;
        }
    }

    .hide-resume-icon {
        // margin-top: 10px;

        .resume-is-hidden-icon {
           fill: $primary;
        }
    }

    .hide-resume-dialog {
        &-text {
            margin: 20px 0 10px;
            font-size: 16px;
            color: #333;
        }

        .el-date-editor--daterange.el-input__inner {
            width: 100%;
            margin-bottom: 20px;
        }
    }
</style>
